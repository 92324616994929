import Dashboard from "layouts/dashboard";
import Documents from "layouts/documents";
import FaceRecognition from "layouts/facerecognition";
import SignIn from "layouts/authentication/sign-in";
import Profile from "layouts/profile";
import Payment from "layouts/payment";
import Icon from "@mui/material/Icon";
import HomePage from "layouts/home";
import DocumentImage from "assets/images/smallIcons/circle2.png";
import FaceImage from "assets/images/smallIcons/circle4.png";
import PhotoImage from "assets/images/smallIcons/circle6.png";
import PaymentImage from "assets/images/smallIcons/circle10.png";
import DashboardImage from "assets/images/smallIcons/circle12.png";
import ProfileImage from "assets/images/smallIcons/circle14.png";
import DocumentActiveImage from "assets/images/smallIcons/circle1.png";
import FaceActiveImage from "assets/images/smallIcons/circle3.png";
import PhotoActiveImage from "assets/images/smallIcons/circle5.png";
import PaymentActiveImage from "assets/images/smallIcons/circle9.png";
import DashboardActiveImage from "assets/images/smallIcons/circle11.png";
import ProfileActiveImage from "assets/images/smallIcons/circle13.png";
import UploadPhoto from "layouts/uploadphoto";
import SearchAddress from "layouts/search-address";
const routes = [
  {
    type: "page",
    name: "Home Page",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    activeIcon: DashboardActiveImage,
    route: "/home",
    component: <HomePage />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: DashboardImage,
    activeIcon: DashboardActiveImage,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Documents",
    key: "documents",
    icon: DocumentImage,
    activeIcon: DocumentActiveImage,
    route: "/documents",
    component: <Documents />,
  },
  {
    type: "collapse",
    name: "Face Recognition",
    key: "face-recognition",
    icon: FaceImage,
    activeIcon: FaceActiveImage,
    route: "/face-recognition",
    component: <FaceRecognition />,
  },
  {
    type: "collapse",
    name: "Upload Photo",
    key: "upload-photo",
    icon: PhotoImage,
    activeIcon: PhotoActiveImage,
    route: "/upload-photo",
    component: <UploadPhoto />,
  },
  {
    type: "collapse",
    name: "Payment",
    key: "payment",
    icon: PaymentImage,
    activeIcon: PaymentActiveImage,
    route: "/payment",
    component: <Payment />,
  },
  // {
  //   type: "collapse",
  //   name: "View Status",
  //   key: "view-status",
  //   icon: StatusImage,
  //   activeIcon: StatusActiveImage,
  //   route: "/view-status",
  //   component: <VerificationStatus />,
  // },

  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: ProfileImage,
    activeIcon: ProfileActiveImage,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "auth",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    activeIcon: ProfileActiveImage,
    route: "/login",
    component: <SignIn />,
  },

  {
    type: "search",
    name: "Search",
    key: "search",
    icon: ProfileImage,
    activeIcon: ProfileActiveImage,
    route: "/search",
    component: <SearchAddress />,
  },
];

export default routes;
